<template>
  <page-layout :avatar="avatar">
    <div slot="headerContent" style="padding-bottom: 10px;">
      <a-row type="flex" justify="start" align="middle">
        <a-col>
          <a-avatar class="avatar" :size="64" v-if="currentUser.headImg" :alt="currentUser.name.substring(0, 1)"
                    :src="bucketUrl+currentUser.headImg"></a-avatar>
          <a-avatar class="avatar" :size="64" v-else>{{currentUser.name.substring(0,1)}}</a-avatar>
        </a-col>
        <a-col style="margin-left: 20px;">
          <div class="title user-name title-text" style="letter-spacing:2px">
            {{ user.name }}
            <span class="welcome-text title-text">，祝你开心工作每一天！</span>
          </div>
          <div class="title-sub" style="letter-spacing:2px">
            <span class="title-sub-text">{{currentUser.organizationJobs[0].organizationName}} </span>
            | {{nowDate}}
          </div>
        </a-col>
      </a-row>
    </div>
    <div v-show="false" slot="extra">
      <a-row class="more-info">
        <a-col :span="8">
          <head-info title="项目数" content="56" :center="false" :bordered="false"/>
        </a-col>
        <a-col :span="8">
          <head-info title="消息通知" content="8/24" :center="false" :bordered="false"/>
        </a-col>
        <a-col :span="8">
          <head-info title="您的待办" content="2,223" :center="false"/>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-row :gutter="24">
        <a-col :xl="16" :lg="24" :md="24" :sm="24" :xs="24" style="height: 100%">
          <surpvise-and-notice></surpvise-and-notice>
        </a-col>
        <a-col
          style="padding: 0 12px"
          :xl="8"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24">
          <sub-user-menu></sub-user-menu>
          <sub-operator></sub-operator>
        </a-col>
      </a-row>
    </div>
  </page-layout>
</template>

<script>
  import { mapGetters } from 'vuex'
  import PageLayout from '@/components/page/PageLayout'
  import HeadInfo from '@/components/tools/HeadInfo'
  import SubOperator from './SubOperator'
  import SubUserMenu from './SubUserMenu'
  import SurpviseAndNotice from './SurpviseAndNotice'
  import moment from 'moment'
  import { mapState } from 'vuex'

  const DataSet = require('@antv/data-set')

  export default {
    name: 'Workplace',
    components: {
      PageLayout,
      SubOperator,
      SubUserMenu,
      SurpviseAndNotice,
      HeadInfo,
    },
    computed: {
      ...mapState({
        currentUser: state => state.user.currentUser,
        bucketUrl: state => {
          return state.user.bucketUrl
        }
      })
    },
    data () {
      return {
        /*timeFix: timeFix(),*/
        avatar: '',
        nowDate: moment().format('MMMM Do YYYY, a'),
        user: {},
        tabList: [{
          key: 'supervise',
          tab: '待办（3）'
        }, {
          key: 'notice',
          tab: '通知消息（30）'
        }],
        key: 'supervise',
        tabKey: 'app',

        projects: [],
        loading: true,
        radarLoading: true,
        activities: [],
        teams: [],
        radarData: [],
        pagination: {
          total: 0,
          pageSize: 10
        }
      }
    },
    created () {
      this.user = this.currentUser
      this.avatar = this.currentUser.avatar
    },
    mounted () {
    },
    methods: {
      onTabChange (key, type) {
        this[type] = key
      },
      ...mapGetters(['nickname', 'welcome']),
      getProjects () {
        this.$http.get('/list/search/projects')
          .then(res => {
            this.projects = res.result && res.result.data
            this.loading = false
          })
      },
      getActivity () {
        this.$http.get('/workplace/activity')
          .then(res => {
            this.activities = res.result
          })
      },
      getTeams () {
        this.$http.get('/workplace/teams')
          .then(res => {
            this.teams = res.result
          })
      },
      initRadar () {
        this.radarLoading = true

        this.$http.get('/workplace/radar')
          .then(res => {
            const dv = new DataSet.View().source(res.result)
            dv.transform({
              type: 'fold',
              fields: ['个人', '团队', '部门'],
              key: 'user',
              value: 'score'
            })

            this.radarData = dv.rows
            this.radarLoading = false
          })
      }
    }
  }
</script>

<style lang="less" scoped>
  .project-list {

    .card-title {
      font-size: 0;

      a {
        color: rgba(0, 0, 0, 0.85);
        margin-left: 12px;
        line-height: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;

        &:hover {
          color: #1890ff;
        }
      }
    }

    .card-description {
      color: rgba(0, 0, 0, 0.45);
      height: 44px;
      line-height: 22px;
      overflow: hidden;
    }

    .project-item {
      display: flex;
      margin-top: 8px;
      overflow: hidden;
      font-size: 12px;
      height: 20px;
      line-height: 20px;

      a {
        color: rgba(0, 0, 0, 0.45);
        display: inline-block;
        flex: 1 1 0;

        &:hover {
          color: #1890ff;
        }
      }

      .datetime {
        color: rgba(0, 0, 0, 0.25);
        flex: 0 0 auto;
        float: right;
      }
    }

    .ant-card-meta-description {
      color: rgba(0, 0, 0, 0.45);
      height: 44px;
      line-height: 22px;
      overflow: hidden;
    }
  }

  .members {
    a {
      display: block;
      margin: 12px 0;
      line-height: 24px;
      height: 24px;

      .member {
        font-size: 14px;
        color: rgba(0, 0, 0, .65);
        line-height: 24px;
        max-width: 100px;
        vertical-align: top;
        margin-left: 12px;
        transition: all 0.3s;
        display: inline-block;
      }

      &:hover {
        span {
          color: #1890ff;
        }
      }
    }
  }

  .mobile {

    .project-list {

      .project-card-grid {
        width: 100%;
      }
    }

    .more-info {
      border: 0;
      padding-top: 16px;
      margin: 16px 0 16px;
    }

    .headerContent .title .welcome-text {
      display: none;
    }
  }

  .title-text {
    color: #17233d;
    font-size: 18px;
  }

  .title-sub {
    margin-top: 8px;
  }

  .title-sub-text {
    color: #808695;
  }

  .user-name {

  }
</style>
