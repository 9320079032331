<template>
  <a-card
    style="width:100%"
    :tabList="tabList"
    :activeTabKey="tabKey"
    @tabChange="key => onTabChange(key, 'tabKey')">
    <!--分页报错-->
    <!--<a-list v-if="tabKey === 'notice'" :dataSource="noticeData" :pagination="pagination">-->
    <a-list v-if="tabKey === 'notice'" :dataSource="noticeData">
      <a-list-item slot="renderItem" slot-scope="item">
        <a-list-item-meta>
          <a-avatar slot="avatar"
                    :src="noticeImage"></a-avatar>
          <div slot="title">
            <span>{{ item.organization.name }}</span>&nbsp;
            发布了&nbsp;<a href="#" @click="noticeClick(item.id)">{{ item.title }}</a>&nbsp;
            <span> &nbsp;的通知</span>&nbsp;
            <span style="display: block;float: right;">{{item.dateCreated|transformTime}}前</span>
          </div>
        </a-list-item-meta>
      </a-list-item>
    </a-list>
    <!--分页报错-->
    <!--<a-list v-if="tabKey === 'news'" :dataSource="newsData" :pagination="pagination">-->
    <a-list v-if="tabKey === 'news'" :dataSource="newsData">
      <a-list-item slot="renderItem" slot-scope="item">
        <a-list-item-meta>
          <a-avatar slot="avatar"
                    :src="newsImage"></a-avatar>
          <div slot="title">
            <span>{{ item.supervise.publisher }}</span>&nbsp;
            <span>向您发起了&nbsp;</span>
            <span>{{ item.supervise.title }}。</span>&nbsp;
          </div>
          <div slot="description">{{item.dateCreated|transformTime}}前</div>
        </a-list-item-meta>
      </a-list-item>
    </a-list>
    <!--分页报错-->
    <!--<a-list v-if="tabKey === 'supervise'" :dataSource="superviseData" :pagination="pagination">-->
    <a-list v-if="tabKey === 'supervise'" :dataSource="superviseData">
      <a-list-item slot="renderItem" slot-scope="item">
        <a-list-item-meta>
          <a-avatar slot="avatar">{{item.user.name}}</a-avatar>
          <div slot="title">
            <span>{{ item.instanceTitle }}。</span>&nbsp;
          </div>
          <div slot="description">{{item.dateCreated|transformTime}}前</div>
        </a-list-item-meta>
        <!--<div>-->
        <!--<a href="">详情</a>-->
        <!--</div>-->
      </a-list-item>
    </a-list>
  </a-card>

</template>

<script>

  import SERVICE_URLS from '../../api/service.url'
  import moment from 'moment'
  import AListItem from 'ant-design-vue/es/list/Item'
  import noticeImgSrc from '../../../public/notice.png'
  import newsImgSrc from '../../../public/news.png'

  export default {
    name: 'SurpviseAndNotice',
    components: { AListItem },
    props: {},
    data() {
      return {
        searchNeedDeal: {
          userId: '',
          ifRead: false,
          number: '0',
          size: '10',
          direction: 'DESC',
          order: 'dateCreated'
        },
        noticeImage: noticeImgSrc,
        newsImage: newsImgSrc,
        searchNotice: {
          read: '',
          receiveId: '',
          number: '0',
          size: '10',
          receiveType: 'all',
          direction: 'DESC',
          order: 'dateCreated'
        },
        searchNews: {
          read: '',
          number: '0',
          size: '10',
          direction: 'DESC',
          order: 'dateCreated'
        },
        tabList: [
          {
            key: 'notice',
            tab: '通知'
          },
          {
            key: 'news',
            tab: '消息'
          },
          {
            key: 'supervise',
            tab: '待办'
          }],
        /*分页*/
        pagination: {
          onChange: (page) => {
            this.loading = true
            if (this.type === 'supervise') {
              this.searchNeedDeal.number = page - 1
              this.loadSuperViseData()
            } else if (this.type === 'notice') {
              this.searchNotice.number = page - 1
              this.loadNoticeData()
            } else if (this.type === 'news') {
              this.searchNews.number = page - 1
              this.loadNewsData()
            }
          },
          total: 0,
          simple: true,
          pageSize: 10,
          showSizeChanger: true
        },
        appModuleType: [
          { value: 'apply', name: '装备申请' },
          { value: 'storage', name: '装备入库' },
          { value: 'allot', name: '装备划拨' },
          { value: 'receive', name: '物资领用' },
          { value: 'borrow', name: '物资借用' },
          { value: 'repair', name: '维修保养' },
          { value: 'scrap', name: '报损报废' },
          { value: 'inventory', name: '装备盘点' },
          { value: 'notice', name: '实验通知单' },
          { value: 'template', name: '实验项目' },
          { value: 'labForm', name: '创建实验项目' },
          { value: 'program', name: '教学计划' }
        ],
        key: 'notice',
        tabKey: 'notice',
        superviseData: [],
        noticeData: [],
        newsData: [],
        url: '',
        type: '',
        avatar: ''
      }
    },
    computed: {
      currentUser() {
        return this.$store.getters.currentUser
      }
    },
    filters: {
      transformTime(time) {
        return moment(time).fromNow(true)
      }
    },
    created() {
      // console.log(this.currentUser)
      this.searchNeedDeal.userId = this.currentUser.id
      this.searchNotice.instancId = this.currentUser.id
      this.avatar = this.currentUser.avatar
      this.loadSuperViseData()
      this.loadNoticeData()
      this.loadNewsData()
    },
    methods: {
      loadSuperViseData() {
        this.$http(this, {
          url: SERVICE_URLS.platBase.needDeal.search,
          data: this.searchNeedDeal,
          noTips: 'true',
          success: (data) => {
            this.superviseData = data.body.content
            this.pagination.total = data.body.totalElements
            this.tabList[2].tab = '待办' + '(' + data.body.totalElements + ')'
          }
        })
      },
      noticeClick(id) {
        this.$router.push(
          {
            path: '/system/notice/detail',
            query: {
              id: id
            }
          }
        )
      },
      loadNewsData() {
        this.$http(this, {
          url: SERVICE_URLS.platBase.superviseState.search,
          data: this.searchNews,
          noTips: 'true',
          success: (data) => {
            this.newsData = data.body.content
            this.pagination.total = data.body.totalElements
            this.tabList[1].tab = '消息' + '(' + data.body.totalElements + ')'
          }
        })
      },
      loadNoticeData() {
        this.$http(this, {
          url: SERVICE_URLS.platBase.notice.list,
          data: this.searchNotice,
          noTips: 'true',
          success: (data) => {
            this.noticeData = data.body.content
            this.pagination.total = data.body.totalElements
            this.tabList[0].tab = '通知'
          }
        })
      },
      onTabChange(key, type) {
        // console.log(key, type)
        this[type] = key
        if (key === 'notice') {
          this.type = key
          this.loadNoticeData()
          this.searchNeedDeal.number = 0
          this.searchNews.number = 0
        } else if (key === 'supervise') {
          this.type = key
          this.loadSuperViseData()
          this.searchNotice.number = 0
          this.searchNews.number = 0
        } else if (key === 'news') {
          this.type = key
          this.loadNewsData()
          this.searchNotice.number = 0
          this.searchNeedDeal.number = 0
        }
      }
    }
  }
</script>