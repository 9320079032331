export const appModuleType = function () {
  return [
    { value: 'apply', name: '装备申请' },
    { value: 'storage', name: '装备入库' },
    { value: 'allot', name: '装备划拨' },
    { value: 'receive', name: '物资领用' },
    { value: 'borrow', name: '物资借用' },
    { value: 'repair', name: '维修保养' },
    { value: 'scrap', name: '报损报废' },
    { value: 'inventory', name: '装备盘点' },
    { value: 'noticebill', name: '教学通知单' },
    { value: 'template', name: '实验项目' },
    { value: 'program', name: '教学计划' },
  ]
}
export const systemModuleType = function () {
  return [
    { value: 'apply', name: '装备申请单' },
    { value: 'storage', name: '装备入库单' },
    { value: 'allot', name: '装备划拨单' },
    { value: 'receive', name: '物资领用单' },
    { value: 'borrow', name: '物资借用单' },
    { value: 'repair', name: '维修保养单' },
    { value: 'scrap', name: '报损报废单' },
    { value: 'inventory', name: '装备盘点单' },
    { value: 'noticebill', name: '教学通知单' },
    { value: 'template', name: '实验项目单' },
    { value: 'program', name: '教学计划单' },
  ]
}
export const instanModuleType = function () {
  return [
    { value: 'apply', name: '装备申请单' },
    { value: 'storage', name: '装备入库单' },
    { value: 'allot', name: '装备划拨单' },
    { value: 'receive', name: '物资领用单' },
    { value: 'borrow', name: '物资借用单' },
    { value: 'repair', name: '维修保养单' },
    { value: 'scrap', name: '报损报废单' },
    { value: 'inventory', name: '装备盘点单' },
    { value: 'noticebill', name: '教学通知单' },
    { value: 'template', name: '实验项目单' },
    { value: 'program', name: '教学计划单' },
  ]
}