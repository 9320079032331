import { render, staticRenderFns } from "./HeadInfo.vue?vue&type=template&id=08d6223d&scoped=true"
import script from "./HeadInfo.vue?vue&type=script&lang=js"
export * from "./HeadInfo.vue?vue&type=script&lang=js"
import style0 from "./HeadInfo.vue?vue&type=style&index=0&id=08d6223d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d6223d",
  null
  
)

export default component.exports